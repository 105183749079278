import React from 'react'
import { motion } from 'framer-motion';
import { fadeIn } from '../../utils/motion';
import Lottie from 'react-lottie';
import animationData from '../../assets/anime.json';
import { TypewriterEffect } from '../aceternity/typewriter-effect';

const Header = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const words = [
        {
            text: "Let's",
            className: "!text-white"
        },
        {
            text: "Build",
            className: "!text-white"
        },
        {
            text: "something",
            className: "!text-white"
        },
        {
            text: "amazing",
            className: "!text-white"
        },
        {
            text: "with",
            className: "!text-white"
        },
        {
            text: "Shara",
            className: "!bg-gradient-text !bg-clip-text !text-transparent"
        },
        {
            text: "solutions.",
            className: "!bg-gradient-text !bg-clip-text !text-transparent"
        },
    ];
    return (
        <div className='flex md:flex-row p-2 xs:flex-col h-screen' id='home'>
            <div className="flex flex-1 justify-center items-start flex-col p-2">
                <div className="font-manrope mb-4 text-left text-6xl font-bold">
                    <TypewriterEffect words={words} />
                </div>
                <motion.p
                    initial='hidden' animate='show'
                    className="mt-4 text-text text-[17px] max-w-3xl leading-[30px]"
                    variants={fadeIn("", "", 4, 4)}
                >Cutting-edge websites and lightning-fast mobile apps – that's our React specialty. Sprinkle in some CSS magic, a dash of Figma/Adobe mastery, plus potent online marketing, and your digital presence explodes!
                </motion.p>
            </div>
            <div className='w-full md:w-2/4'>
                <Lottie options={defaultOptions} />
            </div>
        </div>
    )
}

export default Header;
