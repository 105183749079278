"use client";
import React from "react";
import { CardBody, CardContainer, CardItem } from "../aceternity/3dCard";
import Title from "../elements/Title";

const Projects: React.FC = () => {
    return (
        <section className="flex flex-col">
            <Title title="Our Projects" />
            <CardContainer className="inter-var">
                <CardBody className="bg-gray-50 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
                    <CardItem
                        translateZ="50"
                        className="text-xl font-bold text-neutral-600 dark:text-white"
                    >
                        Bute Constructions
                    </CardItem>
                    <CardItem
                        as="p"
                        translateZ="60"
                        className="text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300"
                    >
                        Welcome to Bute Construction – where innovation meets excellence
                        in every project. Rooted in London's dynamic landscape for over three years,
                        we specialize in highway maintenance, surfacing, ground works, and transformative external projects.
                        Our commitment to sustainability, strategic planning, and cost-effective solutions sets us apart
                    </CardItem>
                    <CardItem translateZ="100" className="w-full mt-4">
                        <img
                            src="https://i.ibb.co/HppZNMc/carrent-cab127a8c09c6eefde7f.png"
                            height="1000"
                            width="1000"
                            className="h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl"
                            alt="thumbnail"
                        />
                    </CardItem>
                    <div className="flex justify-between items-center mt-20">
                        <CardItem
                            translateZ={20}
                            as="p"
                            className="px-4 py-2 rounded-xl text-xs font-normal dark:text-white"
                        >
                            Explore now →
                        </CardItem>
                        <CardItem
                            translateZ={20}
                            as="a"
                            target="__blank"
                            href="https://buteconstruction.co.uk/"
                            className="px-4 py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold"
                        >
                            Click here
                        </CardItem>
                    </div>
                </CardBody>
            </CardContainer>
        </section>
    );
}

export default Projects;