"use client";
import React, { useState } from "react";
import logo from '../../assets/logo.png'
import { HoveredLink, Menu, MenuItem, ProductItem } from "../aceternity/navbar-menu";
import { cn } from "../../utils/cn";



function Navbar({ className }: { className?: string }) {
    const [active, setActive] = useState<string | null>(null);
    return (
        <>
            <div className='m-4'>
                <img src={logo} className="absolute" alt="logo" width={100} height={100} />
            </div>
            <div
                className={cn("fixed top-10 inset-x-0 max-w-2xl mx-auto z-50", className)}
            >
                <Menu setActive={setActive}>
                    <MenuItem setActive={setActive} active={active} item="Services">
                        <div className="flex flex-col space-y-4 text-sm">
                            <HoveredLink href="#1">Web Development</HoveredLink>
                            <HoveredLink href="#2">Mobile App Development</HoveredLink>
                            <HoveredLink href="#3">Graphic Designing</HoveredLink>
                            <HoveredLink href="#4">Search Engine Optimization</HoveredLink>
                        </div>
                    </MenuItem>
                    <MenuItem setActive={setActive} active={active} item="Projects">
                        <div className="  text-sm grid grid-cols-2 gap-10 p-4">
                            <ProductItem
                                title="Bute Constructions"
                                href="https://buteconstructions.ac.uk/"
                                src="https://i.ibb.co/HppZNMc/carrent-cab127a8c09c6eefde7f.png"
                                description=" Welcome to Bute Construction – where innovation meets excellence
                        in every project"
                            />
                        </div>
                    </MenuItem>
                    <MenuItem setActive={setActive} active={active} item="Contact us">
                        <div className="flex flex-col space-y-4 text-sm">
                            <HoveredLink href="#contact">Email</HoveredLink>
                        </div>
                    </MenuItem>
                </Menu>
            </div>
        </>
    );
}

export default Navbar;