import React from 'react'
import { Header, Services, Projects, Footer, Navbar } from './components'
import Contact from './components/Contact/Contact';
import StarsCanvas from './components/Contact/StarCanvas';

const App: React.FC = () => {
  return (
    <div className='min-h-screen p-2 bg-gradient-radial'>
      <Navbar />
      <Header />
      <Services />
      <Projects />
      <div className='relative z-0'>
        <Contact />
        <StarsCanvas />
      </div>
      <Footer />
    </div>
  )
}

export default App;