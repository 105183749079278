import React, { useState, useRef, ChangeEvent, FormEvent } from "react";
import { styles } from './contact.module'
import { motion } from "framer-motion";
import { slideIn } from '../../utils/motion'
import EarthCanvas from './Earth'
import SectionWrapper from './SectionWrapper'

import axios from 'axios'
import './contact.module.css'
import Form from "./Form";


const Contact: React.FC = () => {

    const [form, setForm] = useState({
        fname: "",
        lname: "",
        email: "",
        message: "",
    });
    const [loading, setloading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement | any>) => {
        e.preventDefault();
        setloading(true);
        try {
            const response = await axios.post(
                // "http://localhost:5000/bute-backend/us-central1/app/cMail",
                "https://us-central1-bute-backend.cloudfunctions.net/app/cMail",
                form
            );
            setloading(false);
            setForm({
                fname: "",
                email: "",
                lname: "",
                message: "",
            });
            alert("Your message has been sent successfully!");
        } catch (error) {
            console.error(error);
            setloading(false);
        }
    };

    return (
        <div className="xl:mt-12 xl:flex-row flex-col-reverse flex gap-10 overflow-hidden" id="contact">
            <motion.div
                variants={slideIn("left", "tween", 0.5, 1)}
                className="flex-[0.75] bg-black-100 p-8 rounded-2xl"
            >
                <p className={styles.sectionSubText}>get in touch</p>
                <h3 className={styles.sectionHeadText}>Contact.</h3>

                <Form loading={loading} form={form} setForm={setForm} handleChange={handleChange} handleSubmit={handleSubmit} />
            </motion.div>
            <motion.div
                className="xl:flex-1 xl:h-auto md:h-[550px] h-[350px]"
                variants={slideIn("right", "tween", 0.2, 1)}
            >
                <EarthCanvas />
            </motion.div>
        </div>
    );
};

export default SectionWrapper(Contact, "contact");
