import React, { FC } from 'react'
import { motion } from 'framer-motion'
import { zoomIn } from '../../utils/motion';
import { useInView } from 'react-intersection-observer';

interface TitleProps {
    title: string;
}


const Title: FC<TitleProps> = ({ title }) => {
    const [ref1, inView1] = useInView({
        triggerOnce: true,
    });

    return (
        <div className='flex justify-center flex-col items-center p-2 mb-4'>
            <motion.div ref={ref1} initial='hidden' animate={inView1 ? 'show' : 'hidden'} variants={zoomIn(0, .2)} className='mb-10'>
                <h1 className='bg-gradient-text !bg-clip-text !text-transparent font-manrope tracking-widest text-s text-4xl font-bold uppercase text-center'>{title}</h1>
            </motion.div>
        </div>
    )
}

export default Title;