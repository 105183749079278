import React from 'react'
import { StickyScroll } from '../aceternity/sticky-scroll';
import Title from '../elements/Title';

import { content } from '../data';




const Services = () => {

    return (
        <div className='flex justify-center flex-col items-center p-2 mb-4'>
            <Title title='What we do' />
            <StickyScroll content={content} />
        </div>
    )
}

export default Services;