import Lottie from 'react-lottie';
import web from '../../assets/web.json'
import mobile from '../../assets/mobile.json'
import graphic from '../../assets/graphic.json'
import seo from '../../assets/seo.json'

type AnimationData = typeof web | typeof mobile | typeof graphic | typeof seo;

const createOptions = (animationData: AnimationData) => ({
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
});

const createContent = (options: ReturnType<typeof createOptions>) => (
    <div className="h-full w-full flex items-center justify-center text-white">
        <Lottie options={options} width={300} height={300} />
    </div>
);

export const content = [
    {
        title: "Web Designing",
        description:
            "We craft stunning websites that excel in both form and function. Our designs are visually captivating, easy to navigate, and optimized to work seamlessly across desktop, tablet, and mobile devices. Whether you need a simple brochure site or a complex e-commerce platform, we'll create a website that reflects your brand's unique identity and helps you achieve your online business goals.",
        content: createContent(createOptions(web)),
    },
    {
        title: "Mobile App Designing",
        description:
            "In today's mobile-first world, a well-designed app is essential. We'll create an app experience that's intuitive, engaging, and tailored to your target audience.  Our focus is on delivering smooth, user-friendly interfaces that drive conversions and foster customer loyalty. Let us help you put the power of your business directly into the hands of your customers.",
        content: createContent(createOptions(mobile)),
    },
    {
        title: "Graphic Designing",
        description:
            "Our graphic design services will elevate your brand's visual appeal and leave a lasting impression. From eye-catching logos and captivating illustrations to compelling marketing materials, we create graphics that communicate your message effectively.  We understand the power of visuals in storytelling and work closely with you to ensure your graphics align perfectly with your brand's unique personality.",
        content: createContent(createOptions(graphic)),
    },
    {
        title: "SEO Services",
        description:
            "Don't let your website get lost in the vastness of the internet. Our SEO experts will boost your search engine rankings and attract qualified visitors to your site. We utilize proven strategies, keeping up-to-date with the latest algorithms, to optimize your website for both search engines and your target customers. With our help, you'll see increased visibility, more leads, and a boost in your online conversions.",
        content: createContent(createOptions(seo)),
    },
];